<template>
  <el-alert title="Excel任意位置的内容都将作为编号，用于生成QRCode" style="margin: 20px 0;" type="info" effect="dark" />
  <div class="qr-tool">
    <el-form :inline="true" :model="formInline">
      <el-form-item label="编号">
        <el-input v-model="formInline.no" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">添加</el-button>
      </el-form-item>
      <el-form-item>
        <el-upload class="upload-demo" action="null" :before-upload="handleFile" :file-list="fileList"
          :show-file-list="false">
          <el-button type="primary">导入EXCEL</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item v-if="qrcodes.length !== 0">
        <el-button type="primary" @click="zip">打包下载</el-button>
      </el-form-item>
      <el-form-item label="QRCode显示">
        <el-switch v-model="showQRCode" />
      </el-form-item>
      <el-form-item label="包含编号">
        <el-switch v-model="includeNo" />
      </el-form-item>
    </el-form>
  </div>
  <div class="qr-container">
    <el-card class="qr-card" shadow="hover" v-for="(qr, index) in qrcodes" :key="qr.no">
      <template #header>
        <div class="clearfix">
          <span>{{ qr.no }}</span>
          <el-button @click="onDel(index)" class="qr-action" type="text" icon="el-icon-delete" circle />
          <el-button @click="
              download(qr.url + '?includeNo=' + includeNo, qr.no + '.jpeg')
            " class="qr-action" type="text" icon="el-icon-download" circle />
          <!-- <el-link class="qr-action" :underline="false">查看<i class="el-icon-view el-icon--right" /> </el-link> -->
        </div>
      </template>
      <div>
        <img v-if="showQRCode" class="qr-img" :src="qr.url + '?includeNo=' + includeNo" lazy />
      </div>
    </el-card>
  </div>
</template>

<script>
  import XLSX from "xlsx";

  export default {
    data() {
      return {
        formInline: {
          no: null,
        },
        qrcodes: [],
        fileList: [],
        showQRCode: true,
        includeNo: true,
      };
    },
    created() { },
    mounted() { },
    methods: {
      onSubmit() {
        console.log("no:" + this.formInline.no);

        if (!this.formInline.no) {
          this.$message.warning("请输入编号");
          return;
        }
        this.push(this.formInline.no);
        this.formInline.no = null;
      },
      push(no) {
        // 判断正式还是测试环境
        let apiRoot = process.env.NODE_ENV == "development"
          ? process.env.VUE_APP_BASE_API
          : process.env.VUE_APP_ONLINE_API
        const url = new URL(
          `/wx/qrcode/${no}.jpeg`,
          apiRoot
        );
        this.qrcodes.push({
          no: no,
          url: url.href,
        });
      },
      onDel(index) {
        this.qrcodes.splice(index, 1);
      },
      download(src, fileName) {
        const x = new XMLHttpRequest();
        x.open("GET", src, true);
        x.responseType = "blob";
        x.onload = function (e) {
          const url = window.URL.createObjectURL(x.response);
          const a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          a.click();
        };
        x.send();
      },
      handleFile(file) {
        const self = this;
        const reader = new FileReader();
        reader.onload = function (e) {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const ws = workbook.Sheets[workbook.SheetNames[0]];
          const csv = XLSX.utils.sheet_to_csv(ws);
          console.log(csv);
          const nos = csv
            .split("\n")
            .map((item) => item.split(","))
            .flat()
            .filter((item) => item !== "");
          console.log(nos);

       /**   for (const no of nos) {
            if (no.length !== 7) {
              self.$message.warning("请输入7位编号");
              return false;
            }
          }**/

          nos.forEach((element) => {
            self.push(element);
          });
        };
        reader.readAsArrayBuffer(file);
        return false;
      },
      zip() {
        // 判断正式还是测试环境
        let apiRoot = process.env.NODE_ENV == "development"
          ? process.env.VUE_APP_BASE_API
          : process.env.VUE_APP_ONLINE_API
        const data = this.qrcodes.map((item) => item.no).join(",");
        const url = new URL(
          `/wx/qrcode/zip?no=${data}&includeNo=${this.includeNo}`,
          apiRoot
        );
        this.download(url.href, "qrcode.zip");
      },
    },
  };
</script>

<style lang="scss" scoped>
  .qr-container {
    display: flex;
    flex-wrap: wrap;
  }

  .qr-card {
    width: 300px;
    margin-right: 10px;
    margin-bottom: 10px;

    &:last-child {
      margin-right: 0;
    }
  }

  .qr-img {
    width: 100%;
  }

  .qr-action {
    float: right;
    padding: 3px 0;
    margin-left: 5px;
  }
</style>
